import Swiper, { Autoplay, EffectFade } from 'swiper';

Swiper.use([Autoplay,EffectFade]);
// TOPページのMVスライダー
new Swiper('.p-top-mv__slide-container', {
  slidesPerView: 1,
  speed: 4500,
  loop: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  autoplay: {
    delay: 3000,
  },
  pagination: false,
})