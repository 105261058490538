import $ from 'jquery';

const html = document.querySelector('html');
const headerButton = document.querySelector('.l-header__menu-button');
const headerButtonText = document.querySelector('.l-header__menu-button span');
const headerSideNav = document.querySelector('.l-header__side-nav');
const headerFixMenu = document.querySelector('.l-header__fix-menu');
const headerLanguageMenu = document.querySelector('.l-header__list:last-of-type')

function headerButtonTextChange() {
  if (headerButton.classList.contains('is_open')) {
    headerButtonText.textContent = 'CLOSE';
  } else {
    headerButtonText.textContent = 'MENU';
  }
}

headerButton.addEventListener('click', () => {
  html.classList.toggle('is_hidden');
  headerButton.classList.toggle('is_open');
  headerButtonTextChange();
  headerSideNav.classList.toggle('is_open');
});


const mediaQueryListPC = window.matchMedia('(min-width: 768px)');
const mediaQueryListTAB = window.matchMedia('(min-width: 768px) and (max-width: 1400px)');
const listener = (event) => {
  if (event.matches) {
    window.addEventListener('scroll', () => {
      const scrollValue = window.pageYOffset;
      if (scrollValue < 78) {
        headerFixMenu.classList.remove('is_fixed');
      } else {
        headerFixMenu.classList.add('is_fixed');
      }
    });
  }
};

const buttonHide = (event) => {
  if(event.matches) {
    headerLanguageMenu.addEventListener('mouseover', () => {
      headerButton.style.zIndex = -1;
      headerButton.style.opacity = 0;
    });
    headerLanguageMenu.addEventListener('mouseout', () => {
      headerButton.style.zIndex = 30;
      headerButton.style.opacity = 1;
    });
  }
};

mediaQueryListPC.addEventListener('change', listener);
mediaQueryListTAB.addEventListener('change', buttonHide);

listener(mediaQueryListPC);
buttonHide(mediaQueryListTAB);

$(function () {
  $.getJSON("https://api.openweathermap.org/data/2.5/weather?q=Onna,jp&APPID=0be75e6abc5d08e40a19f714190702ee", function (data) {
      if (!data.cod || data.cod != 200) {
        return;
      }
      const body = document.querySelector('body');
      const headerWeather = document.querySelector('.l-header__weather');
      if(body.classList.contains('js-weather')){
        headerWeather.style.opacity = 1;
        $("#temp").text(Math.floor(data.main.temp - 273.15));
  
        var id = data.weather[0].id;
        var unixTime = Math.floor((new Date()).getTime() / 1000);
        if (data.sys.sunrise <= unixTime && unixTime <= data.sys.sunset) {
          $("#icon").addClass("wi-owm-day-" + id);
        } else {
          $("#icon").addClass("wi-owm-night-" + id);
        }
      } else {
        headerWeather.style.display = 'none';
      }

  });
});