const pagetopButton = document.querySelector('.c-page-top-button');
const footerHeight = document.querySelector('.l-footer__main').clientHeight;
const footerPosition = document.querySelector('.l-footer__main').getBoundingClientRect().top;
const documentHeight = document.body.clientHeight;
const windowHeight = window.innerHeight;


pagetopButton.addEventListener('click', scrollTop);

function scrollTop() {
  window.scroll({ top: 0, behavior: 'smooth' });
}

window.addEventListener('scroll', scroll_event);

function scroll_event() {
  const scrollVolume = window.pageYOffset;
  if (window.pageYOffset > 100) {
    pagetopButton.classList.add('is-show');
  } else if (window.pageYOffset < 100) {
    pagetopButton.classList.remove('is-show');
  }

  if (documentHeight - scrollVolume - windowHeight <= footerHeight) {
    pagetopButton.classList.add('is-stop');
  } else {
    pagetopButton.classList.remove('is-stop');
  }
}


