
const changeImages = [...document.querySelectorAll('.js_change img')];
const thumbnails = [...document.querySelectorAll('.js_get_thumbnail')];

const changeSrcArray = thumbnails.map((e) => {
  return e.getElementsByTagName('img');
})

Object.keys(changeSrcArray).forEach((key) => {
  changeSrcArray[key][0].classList.add('is_current');
  Array.from(changeSrcArray[key]).forEach((e, index) => {
    e.addEventListener('click', () => {
      removeClass(changeSrcArray[key]);
      const changeSrc = e.getAttribute('src');
      e.classList.add('is_current');
      changeImages[key].setAttribute('src', changeSrc);
    })
  })
}, changeSrcArray);

function removeClass(array) {
  for(let i = 0; i < array.length; i++){
    array[i].classList.remove('is_current');
  }
}
